import React, {useState, useMemo, useCallback} from 'react'
import {
  Typography,
  makeStyles,
  InputBase,
  InputAdornment,
  IconButton,
  } from '@material-ui/core'
import cn from 'classname'

export default ({
  helperText,
  isPassword,
  showPasswordStrength,
  password,
  label,
  className,
  themed,
  ...props
}) => {

  const useStyles = makeStyles((theme) => ({
    root: {
      height: 50,
      padding: '1.1rem 1rem',
      boxShadow: '0px 3px 6px #00000029',
      fontSize: "1rem",
      fontWeight: 700,
    },
    themedRoot: {
      border: `1px solid ${theme.palette.text.primary}`,
    },
    input: {
      padding: "0 20px",
      "&::placeholder": {
      },
    },
    themedInput: {
      paddingBottom: 27,
      "&::placeholder": {
        opacity: 0.5,
      },
    },
    disabled: {
      opacity: 0.5,
    },
    label: {
      fontSize: "1rem",
      fontFamily: 'Avenir Black',
      fontWeight: 'bold',
      marginBottom: 2,
    },
    themedLabel: {
    },
    helperLabel: {
      fontSize: "0.875rem",
      marginTop: 8,
      marginLeft: 20,
      opacity: 0.5,
    },
    themedHelperLabel: {
    },
    adornedStart: {
      paddingLeft: 15,
    },
    inputAdornedStart: {
      padding: "0 20px 0 8px",
      "&::placeholder": {
      },
    },
    strengthBar: {
      marginTop: 12,
    },
    scoreWord: {
      fontFamily: "Roboto",
      fontWeight: 700,
      fontSize: "0.75rem",
    },
  }))

  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false);
  const derivedClasses = useMemo(
    () => ({
      inputBaseClasses: {
        root: cn(classes.root, themed ? classes.themedRoot : undefined),
        disabled: classes.disabled,
        adornedStart: classes.adornedStart,
        inputAdornedStart: classes.inputAdornedStart,
      },
    }),
    [classes, themed]
  );

  const onChangeShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);
  return (
    <div className={className}>
      {label && (
        <Typography
          className={cn(
            classes.label,
            themed ? classes.themedLabel : undefined
          )}
        >
          {label}
        </Typography>
      )}
      <InputBase
        fullWidth
        type={isPassword && !showPassword ? "password" : "text"}
        classes={derivedClasses.inputBaseClasses}
        {...props}
        inputProps={{
          autoComplete: "off",
        }}
        endAdornment={
          isPassword ? (
            <InputAdornment position="end">
              <IconButton
                className="far"
                color="primary"
                aria-label="toggle password visibility"
                onClick={onChangeShowPassword}
              >
              </IconButton>
            </InputAdornment>
          ) : undefined
        }
      />
      {helperText && !showPasswordStrength && (
        <Typography
          className={cn(
            classes.helperLabel,
            themed ? classes.themedHelperLabel : undefined
          )}
        >
          {helperText}
        </Typography>
      )}
    </div>
  );
}
